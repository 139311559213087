<svelte:options tag="main-menu"/>

<script>
    export let _mainmenu = mainmenu.querySelectorAll('.menu-item');
    //export let _darkmode = darkmode.getElementsByTagName('li');

    let isMobileMenuOpen = false;

    function toggleMobileMenu() {
        isMobileMenuOpen = !isMobileMenuOpen;
    }

    $: document.body.classList[isMobileMenuOpen ? 'add' : 'remove']('mobile-menu-open');

</script>

<nav class="main-menu" aria-label="Hovedmeny" class:mobile-menu-open={isMobileMenuOpen}>
    <div class="mobile-menu md:hidden">
        <div class="mobile-menu__menu-button-wrapper">
            <button on:click="{toggleMobileMenu}" data-collapse-toggle="mobile-menu__menu-button" type="button"
                    class="mobile-menu__menu-button" aria-controls="mobile-menu__menu"
                    aria-expanded="false">
                <span class="mobile-menu__menu-button__content mobile-menu__menu-button__content--menu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22" fill="none">
                        <path d="M1.85714 4.32846H24.1429C25.168 4.32846 26 3.35889 26 2.16423C26 0.969576 25.168 0 24.1429 0H1.85714C0.832 0 0 0.969576 0 2.16423C0 3.35889 0.832 4.32846 1.85714 4.32846ZM24.1429 8.65693H1.85714C0.832 8.65693 0 9.6265 0 10.8212C0 12.0158 0.832 12.9854 1.85714 12.9854H24.1429C25.168 12.9854 26 12.0158 26 10.8212C26 9.6265 25.168 8.65693 24.1429 8.65693ZM14.8571 17.3139H1.85714C0.832 17.3139 0 18.2834 0 19.4781C0 20.6727 0.832 21.6423 1.85714 21.6423H14.8571C15.8823 21.6423 16.7143 20.6727 16.7143 19.4781C16.7143 18.2834 15.8823 17.3139 14.8571 17.3139Z" fill="#838383"/>
                    </svg>
                </span>
                <span class="mobile-menu__menu-button__content mobile-menu__menu-button__content--close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M2 20L20 2M20 20L2 2" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" fill="#ffffff"/>
                    </svg>
                </span>
                <span class="mobile-menu__menu-button__span sr-only">{isMobileMenuOpen ? 'Lukk hovedmeny' : 'Åpne hovedmeny'}</span>
            </button>
        </div>
        {#if isMobileMenuOpen}
            <nav class="mobile-menu__menu md:hidden" id="mobile-menu__menu">
                <ul>
                    <!--
                    {#each _darkmode as menuitem}
                        <li class="menu-item">{@html menuitem.innerHTML}</li>
                    {/each}
                    -->
                    {#each _mainmenu as menuitem}
                        <li class="mobile-menu__menu-item">{@html menuitem.innerHTML}</li>
                    {/each}
                </ul>
            </nav>
        {/if}
    </div>
    <div class="desktop-menu hidden md:block">
        <ul class="flex">
            <!-- TODO: Darkmode
            {#each _darkmode as menuitem}
                <li class="menu-item">{@html menuitem.innerHTML}</li>
            {/each}
            -->
            {#each _mainmenu as menuitem}
                <li class="desktop-menu__menu-item">{@html menuitem.innerHTML}</li>
            {/each}
        </ul>
    </div>
</nav>

<style>
    @import '/static/website/bundle.css';
</style>
